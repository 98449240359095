import { IconProps } from "./IconProps";

function FileChartIcon({
	width = "18",
	height = "23",
	color = "#223234",
	viewBox = "0 0 18 23",
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11 1.76953V5.90007C11 6.46012 11 6.74015 11.109 6.95406C11.2049 7.14222 11.3578 7.2952 11.546 7.39108C11.7599 7.50007 12.0399 7.50007 12.6 7.50007H16.7305M5 14.5V17.5M13 12.5V17.5M9 10V17.5M17 9.48822V16.7C17 18.3802 17 19.2202 16.673 19.862C16.3854 20.4265 15.9265 20.8854 15.362 21.173C14.7202 21.5 13.8802 21.5 12.2 21.5H5.8C4.11984 21.5 3.27976 21.5 2.63803 21.173C2.07354 20.8854 1.6146 20.4265 1.32698 19.862C1 19.2202 1 18.3802 1 16.7V6.3C1 4.61984 1 3.77976 1.32698 3.13803C1.6146 2.57354 2.07354 2.1146 2.63803 1.82698C3.27976 1.5 4.11984 1.5 5.8 1.5H9.01178C9.74555 1.5 10.1124 1.5 10.4577 1.58289C10.7638 1.65638 11.0564 1.77759 11.3249 1.94208C11.6276 2.1276 11.887 2.38703 12.4059 2.90589L15.5941 6.09411C16.113 6.61297 16.3724 6.8724 16.5579 7.17515C16.7224 7.44356 16.8436 7.7362 16.9171 8.0423C17 8.38757 17 8.75445 17 9.48822Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
export default FileChartIcon;
