import { useStore } from "@nanostores/react";
import LeftRail, { LeftRailProps } from "common/components/Nav/LeftRail";
import OpteraAppBar, {
	OpteraAppBarProps,
} from "common/components/Nav/OpteraAppBar";
import MuiXLicense from "common/utils/MuiXLicense";
import { railExpandedStore } from "../stores/NavigationRailStore";
import { useEffect } from "react";

interface LayoutProps {
	children: React.ReactElement | React.ReactElement[];
	appBarProps?: OpteraAppBarProps;
	leftRailProps?: LeftRailProps;
}

export default function OpteraLayout({
	children,
	appBarProps,
	leftRailProps,
}: LayoutProps) {
	const $railExpanded = useStore(railExpandedStore);

	useEffect(() => {
		if (leftRailProps?.initialExpanded != null) {
			railExpandedStore.set(leftRailProps.initialExpanded);
		}
	}, [leftRailProps?.initialExpanded]);

	return (
		<div id="opteraLayout-wrapper" className={$railExpanded ? "nav-wide" : ""}>
			<OpteraAppBar {...appBarProps} />
			<LeftRail {...leftRailProps} />
			<main>{children}</main>
			<MuiXLicense />
		</div>
	);
}
