"use client";

import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
	"12330d768f11e3656e85f3b2f678e5f7Tz04OTA1NyxFPTE3NDU1MzMyODIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

export default function MuiXLicense() {
	return null;
}
