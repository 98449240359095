import { IconProps } from "./IconProps";

function HelpIcon({
	width = "24",
	height = "24",
	color = "#223234",
	viewBox = "0 0 24 24",
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11.2581 7.02432C10.7926 6.94447 10.3138 7.03195 9.90664 7.27127C9.49944 7.51058 9.19007 7.88629 9.03333 8.33184C8.85006 8.85283 8.27915 9.12661 7.75816 8.94333C7.23717 8.76006 6.96339 8.18915 7.14667 7.66816C7.46014 6.77705 8.07887 6.02563 8.89327 5.547C9.70768 5.06837 10.6652 4.89341 11.5962 5.05311C12.5273 5.2128 13.3718 5.69686 13.9801 6.41953C14.5883 7.14205 14.9213 8.05648 14.92 9.00091C14.9195 10.5313 13.7849 11.5419 12.9747 12.0821C12.5391 12.3725 12.1106 12.586 11.7949 12.7263C11.6356 12.7971 11.5015 12.8508 11.4049 12.8876C11.3565 12.9061 11.3173 12.9203 11.2887 12.9305L11.254 12.9427L11.2429 12.9464L11.239 12.9478L11.2375 12.9483C11.2372 12.9484 11.2362 12.9487 10.92 12L11.2362 12.9487C10.7123 13.1233 10.146 12.8402 9.97132 12.3162C9.79673 11.7925 10.0796 11.2263 10.6032 11.0515L10.6187 11.0461C10.6341 11.0406 10.6593 11.0314 10.6929 11.0186C10.7603 10.9929 10.8606 10.9529 10.9826 10.8987C11.2294 10.789 11.5509 10.6275 11.8653 10.4179C12.5549 9.95822 12.92 9.46913 12.92 9L12.92 8.99851C12.9207 8.52619 12.7542 8.06886 12.4501 7.70753C12.1459 7.34619 11.7236 7.10417 11.2581 7.02432ZM10 16C10 15.4477 10.4477 15 11 15H11.01C11.5623 15 12.01 15.4477 12.01 16C12.01 16.5523 11.5623 17 11.01 17H11C10.4477 17 10 16.5523 10 16Z"
				fill={color}
			/>
		</svg>
	);
}
export default HelpIcon;
